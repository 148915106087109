var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
var SCloseCrossWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: translateX(50%);\n\n  cursor: pointer;\n  --close-color: ", ";\n\n  :hover {\n    --close-color: ", ";\n  }\n\n  svg path {\n    fill: var(--close-color);\n  }\n"], ["\n  transform: translateX(50%);\n\n  cursor: pointer;\n  --close-color: ", ";\n\n  :hover {\n    --close-color: ", ";\n  }\n\n  svg path {\n    fill: var(--close-color);\n  }\n"])), function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var hoverColor = _a.hoverColor, color = _a.color;
    return hoverColor || color;
});
export var CloseCross = function (_a) {
    var color = _a.color, hoverColor = _a.hoverColor, onClick = _a.onClick;
    return (_jsx(SCloseCrossWrapper, __assign({ color: color, hoverColor: hoverColor, onClick: onClick }, { children: _jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M3.49998 1.37878L4.56064 2.43944L12 9.87878L19.4393 2.43944L20.5 1.37878L22.6213 3.5001L21.5606 4.56076L14.1213 12.0001L21.5606 19.4394L22.6213 20.5001L20.5 22.6214L19.4393 21.5608L12 14.1214L4.56064 21.5608L3.49998 22.6214L1.37866 20.5001L2.43932 19.4394L9.87866 12.0001L2.43932 4.56076L1.37866 3.5001L3.49998 1.37878Z" }) })) })));
};
var templateObject_1;
