import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { backendInstance } from "../api/axios";
import { Pagination, Table } from "flowbite-react";
import { TableRowSkeleton } from "../components/skeletons/table-row-skeleton";
export default function ConnectedWallets() {
    const [wallets, setWallets] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 10;
    useEffect(() => {
        setLoading(true);
        backendInstance.get(`/user/all?skip=0&take=${pageSize}`).then(res => {
            setCount(res.data.count);
            setWallets(res.data.users);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        setLoading(true);
        backendInstance.get(`/user/all?skip=${(pageNumber - 1) * pageSize}&take=${pageSize}`).then(res => {
            setWallets(res.data.users);
            setLoading(false);
        });
    }, [pageNumber]);
    const onPageChange = useCallback((page) => {
        setPageNumber(page);
    }, [setPageNumber]);
    return (_jsxs("div", { className: "", children: [_jsxs(Table, { hoverable: true, className: "whitespace-nowrap", children: [_jsxs(Table.Head, { children: [_jsx(Table.HeadCell, { children: "No" }), _jsx(Table.HeadCell, { children: "Wallet" })] }), _jsx(Table.Body, { className: "divide-y", children: loading ? (_jsx(Table.Row, { children: _jsx(TableRowSkeleton, {}) })) : (wallets.map((wallet, index) => (_jsxs(Table.Row, { className: "bg-white border-none dark:bg-gray-800 hover:bg-hoverColor cursor-pointer text-title", children: [_jsx(Table.Cell, { children: _jsx("span", { className: "font-bold", children: pageSize * (pageNumber - 1) + index + 1 }) }), _jsx(Table.Cell, { children: _jsx("span", { className: 'font-bold', children: wallet.address }) })] }, `funds-holding-table-row-${wallet.address}`)))) })] }), _jsx(Pagination, { className: "all-funds-pagination flex items-center text-[12px] md:text-[14px] mx-auto mt-5 justify-center", currentPage: pageNumber, totalPages: Math.ceil((count || 0) / pageSize), onPageChange: onPageChange, showIcons: true, previousLabel: "", nextLabel: "" })] }));
}
