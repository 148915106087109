var EventController = /** @class */ (function () {
    function EventController() {
        this._eventCallbacks = [];
    }
    EventController.prototype.on = function (eventCallback) {
        this._eventCallbacks.push(eventCallback);
    };
    EventController.prototype.off = function (eventObj) {
        // remove specific event callback
        if (eventObj) {
            if (eventObj.callback) {
                this._eventCallbacks = this._eventCallbacks.filter(function (eventCallback) {
                    return eventCallback.event !== eventObj.event ||
                        eventCallback.callback !== eventObj.callback;
                });
            } // No callback to remove, remove entire event
            else {
                this._eventCallbacks = this._eventCallbacks.filter(function (eventCallback) {
                    return eventCallback.event !== eventObj.event;
                });
            }
        }
        else {
            this._eventCallbacks = [];
        }
    };
    EventController.prototype.trigger = function (event, result) {
        var eventCallbacks = this._eventCallbacks.filter(function (eventCallback) { return eventCallback.event === event; });
        if (eventCallbacks === null || eventCallbacks === void 0 ? void 0 : eventCallbacks.length) {
            eventCallbacks.forEach(function (eventCallback) {
                eventCallback.callback(result);
            });
        }
    };
    return EventController;
}());
export { EventController };
