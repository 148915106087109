// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  background: #8c52ff !important;
  color: #fff !important;
}

.active svg {
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/sidebar.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".active {\n  background: #8c52ff !important;\n  color: #fff !important;\n}\n\n.active svg {\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
