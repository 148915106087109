var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { browserName, isIOS, isMobile } from "react-device-detect";
import { checkIsCurrentBrowser } from "../helpers/utils";
import { ProviderCard } from "./ProviderCard";
export var CardManager = function (props) {
    var connectorType = props.connectorType, options = props.options, isBadBrowser = props.isBadBrowser, allBrowsersNames = props.allBrowsersNames, browsersNames = props.browsersNames;
    var _a = useState(), isProviderExist = _a[0], setIsProviderExist = _a[1];
    var _b = useState(), isCurrentBrowser = _b[0], setIsCurrentBrowser = _b[1];
    var checking = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isCurrentBrowser, result, _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    isCurrentBrowser = checkIsCurrentBrowser(options === null || options === void 0 ? void 0 : options.isCurrentBrowser).isCurrentBrowser;
                    if (!isCurrentBrowser) return [3 /*break*/, 2];
                    return [4 /*yield*/, ((_b = options === null || options === void 0 ? void 0 : options["checkIsProviderExist"]) === null || _b === void 0 ? void 0 : _b.call(options))];
                case 1:
                    _a = _c.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = false;
                    _c.label = 3;
                case 3:
                    result = _a;
                    setIsCurrentBrowser(isCurrentBrowser);
                    setIsProviderExist(result);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        checking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);
    // вывод списка под разные платформы
    if (connectorType) {
        if (options === null || options === void 0 ? void 0 : options["hide"])
            return null; // hide card if option
        if (isMobile) {
            if (isIOS) {
                return connectorType === "ios" ? (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: true, isProviderExist: true, isFirst: false }))) : null;
            }
            else {
                return connectorType === "android" ? (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: true, isProviderExist: true, isFirst: false }))) : null;
            }
        }
        else {
            if (connectorType === "mobile") {
                return (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: true, isProviderExist: true })));
            }
            if (connectorType === "extension") {
                if (isCurrentBrowser) {
                    if (isProviderExist === undefined)
                        return _jsx(_Fragment, { children: "..." });
                    return (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: isCurrentBrowser, isProviderExist: isProviderExist, browser: browserName, isBadBrowser: isBadBrowser, allBrowsersNames: allBrowsersNames, browsersNames: browsersNames })));
                }
                else {
                    return (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: false, isProviderExist: false, onClick: undefined, isFirst: props.isFirst })));
                }
            }
        }
        return null;
    }
    return (_jsx(ProviderCard, __assign({}, props, { isCurrentBrowser: true, isProviderExist: true })));
};
