var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import QRCode from "react-qr-code";
import styled from "styled-components";
var SQrBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  --x: 160px;\n  min-width: var(--x);\n  min-height: var(--x);\n\n  border: 1px solid #ececec;\n  border-radius: 8px;\n  padding: 7px;\n\n  /* ========================= */\n  background: ", ";\n  border: 1px solid\n    ", ";\n  /* ========================= */\n\n  background-position: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  --x: 160px;\n  min-width: var(--x);\n  min-height: var(--x);\n\n  border: 1px solid #ececec;\n  border-radius: 8px;\n  padding: 7px;\n\n  /* ========================= */\n  background: ", ";\n  border: 1px solid\n    ", ";\n  /* ========================= */\n\n  background-position: center;\n"])), function (_a) {
    var _b = _a.themeConfig, name = _b.name, _c = _b.theme, color = _c.item.background.color, text = _c.common.text;
    return (name === "dark" ? text.color : color);
}, function (_a) {
    var color = _a.themeConfig.theme.item.border.color;
    return color;
});
export var QrCard = function (_a) {
    var themeConfig = _a.themeConfig, qr = _a.qr;
    return !!qr ? (_jsx(SQrBox, __assign({ themeConfig: themeConfig }, { children: _jsx(QRCode, { value: qr, bgColor: themeConfig.name === "dark"
                ? themeConfig.theme.common.text.color
                : themeConfig.theme.item.background.color, fgColor: themeConfig.name === "dark"
                ? themeConfig.theme.popup.background.color
                : themeConfig.theme.common.text.color, size: 144 }) }))) : null;
};
var templateObject_1;
