var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getThemeWithFallback = function (theme) {
    return __assign(__assign({}, theme), { popup: __assign(__assign({}, theme.popup), { text: theme.common.text }), item: __assign(__assign({}, theme.item), { text: theme.common.text, warning: __assign(__assign({}, theme.item.warning), { text: theme.item.warning.text }) }) });
};
