import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTopDexfunds } from '../../api/funds';
const initialState = {
    data: [],
    loading: true
};
export const loadTopDexfunds = createAsyncThunk('topDexfunds/loadTopDexfunds', async () => {
    const funds = await getTopDexfunds();
    return funds;
});
export const topDexfundsSlice = createSlice({
    name: 'topDexfunds',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loadTopDexfunds.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(loadTopDexfunds.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadTopDexfunds.rejected, (state) => {
            state.loading = false;
        });
    }
});
export default topDexfundsSlice.reducer;
