import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { NavbarCustom } from './navbar-custom';
import { SidebarCustom } from './sidebar-custom';
import useMediaQuery from '../hooks/useMediaQuery';
export const Layout = ({ everWallet, typeNetwork, venomConnect, venomProvider, address, balance, setTypeNetwork, venomWallet, children }) => {
    const matches = useMediaQuery('(max-width: 1236px)'); // 1536px
    const [collapsed, setCollapsed] = useState(false);
    useEffect(() => {
        setCollapsed(matches);
    }, [matches]);
    const onClickToggle = () => {
        setCollapsed(!collapsed);
    };
    return (_jsx("div", { className: "flex justify-center w-full bg-[#fff] md:pb-2 h-screen", children: _jsxs("div", { className: "flex w-full max-w-[1536px] md:gap-[20px] rounded-md h-fit", children: [_jsx(SidebarCustom, { collapsed: collapsed, hide: () => setCollapsed(true), venomWallet: venomWallet }), _jsxs("div", { className: "app-layout border-2 flex flex-col flex-1 border-none dark:bg-gray-800 h-screen overflow-x-hidden", children: [_jsx(NavbarCustom, { everWallet: everWallet, typeNetwork: typeNetwork, venomConnect: venomConnect, venomProvider: venomProvider, address: address, balance: balance, setTypeNetwork: setTypeNetwork, onClickToggle: onClickToggle, venomWallet: venomWallet }), _jsx("div", { className: "page-layout md:mt-2 w-full flex-1 pb-5 2xl:px-0 px-[10px]", children: children })] })] }) }));
};
