import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import currencyReducer from './slices/currency.slice';
import allFundsReducer from './slices/funds.slice';
import topDexfundsReducer from './slices/top-dexfunds.slice';
import fundDetailsReducer from './slices/fund-details';
export const store = configureStore({
    reducer: {
        currency: currencyReducer,
        allFunds: allFundsReducer,
        topDexfunds: topDexfundsReducer,
        fundDetails: fundDetailsReducer
    }
});
export const useAppDispatch = () => useDispatch();
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppSelector = useSelector;
