import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCurrencies } from '../../api/currencies';
const initialState = {
    data: [],
    loading: true
};
export const loadAllCurrencies = createAsyncThunk('currency/getAllCurrencies', async () => {
    const currencies = await getAllCurrencies();
    return currencies;
});
export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loadAllCurrencies.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(loadAllCurrencies.rejected, (state) => {
            state.loading = false;
        });
    }
});
export default currencySlice.reducer;
