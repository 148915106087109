import { backendInstance } from './axios';
export const getAllFunds = async () => {
    const response = await backendInstance.get('fund');
    return response.data;
};
export const getTopDexfunds = async () => {
    const response = await backendInstance.get('fund/top-dexfunds');
    return response.data;
};
export const getFundDetails = async (id) => {
    const response = await backendInstance.get(`fund/${id}`);
    return response.data;
};
export const getFundAction = async (id, take, skip) => {
    const response = await backendInstance.get(`fund/fund-action/${id}?take=${take}&skip=${skip}`);
    return response.data;
};
export const getFundsByCategory = async (category, limit, skip) => {
    const response = await backendInstance.get(`fund/funds/${category}?limit=${limit}&skip=${skip}`);
    return response.data;
};
export const postFund = (address, userAddress, signature, file, category, description) => new Promise(async (resolve, reject) => {
    try {
        const data = new FormData();
        data.append('address', address);
        data.append('userAddress', userAddress);
        data.append('signature', signature);
        if (file) {
            data.append('file', file);
        }
        // @ts-ignore
        if (category)
            data.append('category', category);
        if (description)
            data.append('description', description);
        const res = await backendInstance.post('fund', data);
        resolve(res.data);
    }
    catch (error) {
        reject(error);
    }
});
