import { AnalyticsBrowser } from "@segment/analytics-next";
const writeKey = "sKuEx9KCduRABALVzSRzmIOoxiuZ2ASE";
export const analytics = AnalyticsBrowser.load({ writeKey });
export var EventNames;
(function (EventNames) {
    EventNames["WalletConnected"] = "Wallet Connected";
    EventNames["WalletDisconnected"] = "Wallet Disconnected";
})(EventNames || (EventNames = {}));
export var MenuitemClickTitle;
(function (MenuitemClickTitle) {
    MenuitemClickTitle["CopyAddress"] = "Copy Address";
    MenuitemClickTitle["ViewExplorer"] = "View Explorer";
})(MenuitemClickTitle || (MenuitemClickTitle = {}));
export const identify = (wallet) => {
    try {
        analytics.identify(wallet);
    }
    catch (error) {
        console.error("identify", error);
    }
};
export const emitWalletConnectedTrack = (wallet) => {
    try {
        analytics.track(EventNames.WalletConnected, {
            walletId: wallet,
        });
    }
    catch (error) {
        console.error("emitWalletConnectedTrack", error);
    }
};
