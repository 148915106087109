import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ProviderRpcClient } from 'everscale-inpage-provider';
import { EverscaleStandaloneClient } from 'everscale-standalone-client';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import { App } from './App';
import { EverWalletProvider } from './logic/wallet/hook';
import { store } from './store';
import 'react-notifications-component/dist/theme.css';
import './index.css';
const provider = new ProviderRpcClient({
    fallback: () => EverscaleStandaloneClient.create({
        connection: {
            id: 2,
            type: 'graphql',
            data: {
                // create your own project at https://dashboard.evercloud.dev
                endpoints: ['https://mainnet.evercloud.dev/ef45648dfd964b85b1476c3dfee79d11/graphql']
            }
        }
    })
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsxs(React.StrictMode, { children: [_jsx(ReactNotifications, {}), _jsx(BrowserRouter, { children: _jsx(Provider, { store: store, children: _jsx(EverWalletProvider, { ever: provider, children: _jsx(App, {}) }) }) })] }));
