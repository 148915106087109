import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFundAction, getFundDetails } from '../../api/funds';
const initialState = {
    data: {},
    fundActions: [],
    loading: true
};
export const loadFundDetailsData = createAsyncThunk('fundDetails/loadFundDetails', async (id) => {
    const fund = await getFundDetails(id);
    return fund;
});
export const getFundActionsData = createAsyncThunk('fundDetails/getFundActions', async ({ address, take, skip }) => {
    const funds = await getFundAction(address, take, skip);
    return funds;
});
export const fundDetailsSlice = createSlice({
    name: 'fundDetails',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loadFundDetailsData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(loadFundDetailsData.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getFundActionsData.fulfilled, (state, action) => {
            state.fundActions = action.payload;
        });
    }
});
export default fundDetailsSlice.reducer;
