import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllFunds, getFundsByCategory } from '../../api/funds';
const initialState = {
    data: [],
    loading: true,
    categoryFunds: {
        categoryData: [],
        categoryLoading: true
    }
};
export const loadAllFunds = createAsyncThunk('allFunds/loadAllFunds', async () => {
    const funds = await getAllFunds();
    return funds;
});
export const loadFundsByCategory = createAsyncThunk('allFunds/loadFundsByCategory', async ({ category, limit, skip }) => {
    const funds = await getFundsByCategory(category, limit, skip);
    return funds;
});
export const allFundsSlice = createSlice({
    name: 'allFunds',
    initialState,
    reducers: {
        addFund: (state, action) => {
            const newData = state.data;
            newData.push(action.payload);
            state = {
                ...state,
                data: newData
            };
        },
        clearCategories: (state) => {
            state.categoryFunds.categoryData = [];
        }
    },
    extraReducers(builder) {
        builder.addCase(loadAllFunds.pending, (state) => {
            state.data = [];
            state.loading = true;
        });
        builder.addCase(loadAllFunds.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(loadAllFunds.rejected, (state) => {
            state.data = [];
            state.loading = false;
        });
        builder.addCase(loadFundsByCategory.pending, (state) => {
            state.categoryFunds.categoryData = [],
                state.categoryFunds.categoryLoading = true;
        });
        builder.addCase(loadFundsByCategory.fulfilled, (state, action) => {
            state.categoryFunds.categoryData = action.payload,
                state.categoryFunds.categoryLoading = false;
        });
        builder.addCase(loadFundsByCategory.rejected, (state) => {
            state.categoryFunds.categoryData = [],
                state.categoryFunds.categoryLoading = false;
        });
    }
});
export const { addFund, clearCategories } = allFundsSlice.actions;
export default allFundsSlice.reducer;
