var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
var ShowNetworkWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 320px;\n\n  margin-top: 9px;\n"], ["\n  width: 100%;\n  max-width: 320px;\n\n  margin-top: 9px;\n"])));
var ShowNetwork = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 56px;\n  border: ", " 1px dashed;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 8px;\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 20px;\n  color: ", ";\n"], ["\n  width: 100%;\n  height: 56px;\n  border: ", " 1px dashed;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: 8px;\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 20px;\n  color: ", ";\n"])), function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var color = _a.color;
    return color;
});
var ChangeWalletButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #11a97d;\n  width: 100%;\n  max-width: 320px;\n  height: 56px;\n  color: white;\n  border-radius: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 20px;\n"], ["\n  background: #11a97d;\n  width: 100%;\n  max-width: 320px;\n  height: 56px;\n  color: white;\n  border-radius: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 20px;\n"])));
var TextDiv = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 12px;\n  line-height: 16px;\n  width: 100%;\n\n  margin: 12px 0;\n  text-align: ", ";\n"], ["\n  font-size: 12px;\n  line-height: 16px;\n  width: 100%;\n\n  margin: 12px 0;\n  text-align: ", ";\n"])), function (_a) {
    var textAlign = _a.textAlign;
    return textAlign || "center";
});
export var WrongNetworkPopup = function (_a) {
    var textColor = _a.textColor, networkName = _a.networkName, changeWallet = _a.changeWallet, disconnect = _a.disconnect;
    return (_jsxs(_Fragment, { children: [_jsxs(ShowNetworkWrapper, { children: [_jsx(TextDiv, __assign({ textAlign: "center" }, { children: "Please change network in your wallets settings to" })), _jsx(ShowNetwork, __assign({ color: textColor }, { children: networkName }))] }), _jsx(TextDiv, __assign({ textAlign: "center" }, { children: "or" })), disconnect ? (_jsx(ChangeWalletButton, __assign({ onClick: disconnect }, { children: "Disconnect" }))) : (_jsx(ChangeWalletButton, __assign({ onClick: changeWallet }, { children: "Change Wallet" })))] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
