import { getValueByKey } from ".";
import Apple from "../logos/Apple.svg";
import PlayMarket from "../logos/PlayMarket.svg";
// todo logos
import MobileApp from "../logos/MobileAppCommon.svg";
// for oxychat
var oxychatDefaultLink = "https://oxy.page.link/link";
var oxychatIosDeepLink = undefined;
var oxychatAndroidDeepLink = undefined;
var oxychatExtensionLinkChrome = oxychatDefaultLink;
export var oxychatDefaultLinks = {
    ios: oxychatIosDeepLink,
    android: oxychatAndroidDeepLink,
    qr: undefined,
    extension: [
        {
            browser: "chrome",
            link: oxychatExtensionLinkChrome !== null
                ? oxychatExtensionLinkChrome || oxychatDefaultLink
                : null,
        },
    ],
};
var OxychatWalletLogos = {
    connectors: {
        extension: MobileApp,
        ios: MobileApp,
        android: MobileApp,
        mobile: MobileApp,
        apple: Apple,
        playMarket: PlayMarket,
    },
};
export var getOxyQr = function (link) {
    return (
    // url
    //
    oxychatDefaultLink +
        //
        // params
        //
        "?link=" +
        (link || encodeURIComponent(window.location.href)));
};
export var getOxyIos = getOxyQr;
export var getOxyAndroid = getOxyQr;
export var oxychatwallet = {
    id: "oxychatwallet",
    walletWaysToConnect: [
        {
            id: "extension",
            type: "extension",
            logo: {
                chrome: OxychatWalletLogos.connectors.extension,
            },
            name: "OXY.CHAT",
            options: {
                isCurrentBrowser: [["isFalse", "isFalse"]],
                installExtensionLink: function (links) {
                    return getValueByKey("oxychatwallet", "extension")(links);
                },
                checkIsProviderExist: function () { return !!window.__oxy; },
                hide: true,
            },
        },
        {
            id: "mobile",
            type: "mobile",
            logo: OxychatWalletLogos.connectors.mobile,
            name: "OXY.CHAT Mobile App",
            options: {
                qr: function (links) {
                    return getValueByKey("oxychatwallet", "qr")(links);
                },
                devises: [
                    {
                        type: "ios",
                        img: OxychatWalletLogos.connectors.apple,
                        text: "iOS App",
                        deepLink: "https://apps.apple.com/th/app/oxy-chat-call-send-receive/id1606970462",
                        alt: "iOS",
                        storeId: "ios",
                    },
                    {
                        type: "android",
                        img: OxychatWalletLogos.connectors.playMarket,
                        text: "Android App",
                        deepLink: "https://play.google.com/store/apps/details?id=com.oxy.chat",
                        alt: "Android",
                        storeId: "android",
                    },
                    // {
                    //   type: "etc",
                    //   img: OxychatWalletLogos.connectors.playMarket,
                    //   text: "For Desktop",
                    //   deepLink: "https://oxy.chat/downloads",
                    //   alt: "Desktop",
                    //   storeId: "desktop",
                    // },
                ],
            },
        },
        {
            id: "ios",
            type: "ios",
            logo: OxychatWalletLogos.connectors.ios,
            name: "OXY.CHAT Mobile App",
            options: {
                text: "Click here to open App Store",
                deepLink: function (links) {
                    if (links === void 0) { links = oxychatDefaultLinks; }
                    return getValueByKey("oxychatwallet", "ios")(links);
                },
            },
        },
        {
            id: "android",
            type: "android",
            logo: OxychatWalletLogos.connectors.android,
            name: "OXY.CHAT Mobile App",
            options: {
                text: "Click here to open Google Play",
                deepLink: function (links) {
                    if (links === void 0) { links = oxychatDefaultLinks; }
                    return getValueByKey("oxychatwallet", "android")(links);
                },
            },
        },
    ],
};
