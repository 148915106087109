import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { VenomConnect } from 'venom-connect';
import { EverscaleStandaloneClient } from 'everscale-standalone-client';
import { Address, ProviderRpcClient } from 'everscale-inpage-provider';
import { Layout, LoadingScreen } from './layout';
import { useEverWallet } from './logic/wallet/useEverWallet';
import useAllDexfunds from './hooks/useAllDexfunds';
import useTopDexfunds from './hooks/useTopDexfunds';
import { emitWalletConnectedTrack, identify } from './helpers/analytics';
import { backendInstance } from './api/axios';
import ConnectedWallets from './pages/connected-wallets';
const Home = lazy(async () => import('./pages/home'));
const Fund = lazy(async () => import('./pages/fund'));
const AllFunds = lazy(async () => import('./pages/all-funds'));
const CreateVault = lazy(async () => import('./pages/create-vault'));
const initTheme = 'light';
const standaloneFallback = () => EverscaleStandaloneClient.create({
    connection: {
        id: 1000,
        group: 'venom_testnet',
        type: 'jrpc',
        data: { endpoint: 'https://jrpc-devnet.venom.foundation/' }
    }
});
const initVenomConnect = async () => new VenomConnect({
    theme: initTheme,
    checkNetworkId: 1000,
    providersOptions: {
        venomwallet: {
            links: {},
            walletWaysToConnect: [
                {
                    // NPM package
                    package: ProviderRpcClient,
                    packageOptions: {
                        fallback: VenomConnect.getPromise('venomwallet', 'extension') || (() => Promise.reject()),
                        forceUseFallback: true
                    },
                    packageOptionsStandalone: {
                        fallback: standaloneFallback,
                        forceUseFallback: true
                    },
                    id: 'extension',
                    type: 'extension'
                }
            ],
            defaultWalletWaysToConnect: [
                // List of enabled options
                'mobile',
                'ios',
                'android'
            ]
        }
    }
});
export const App = () => {
    useTopDexfunds();
    useAllDexfunds();
    const [firstRender, setFirstRender] = useState(false);
    const [typeNetwork, setTypeNetwork] = useState('venom');
    const [venomConnect, setVenomConnect] = useState();
    const [venomWallet, setVenomWallet] = useState();
    const [venomProvider, setVenomProvider] = useState();
    const [address, setAddress] = useState();
    const [publicKey, setPublicKey] = useState();
    const [balance, setBalance] = useState();
    const everWallet = useEverWallet();
    const getAddress = async (provider) => {
        const providerState = await provider?.getProviderState?.();
        const address2 = providerState?.permissions.accountInteraction?.address.toString();
        const publicKey2 = providerState?.permissions.accountInteraction?.publicKey.toString();
        setPublicKey(publicKey2);
        return address2;
    };
    const getBalance = async (provider, _address) => {
        try {
            const providerBalance = await provider?.getBalance?.(_address);
            return providerBalance;
        }
        catch (error) {
            return undefined;
        }
    };
    const checkAuth = async (_venomConnect) => {
        const auth = await _venomConnect?.checkAuth();
        if (auth)
            await getAddress(_venomConnect);
    };
    const onInitButtonClick = async () => {
        const initedVenomConnect = await initVenomConnect();
        setVenomConnect(initedVenomConnect);
        await checkAuth(initedVenomConnect);
    };
    const check = async (_provider) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _address = _provider ? await getAddress(_provider) : undefined;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _balance = _provider && _address ? await getBalance(_provider, _address) : undefined;
        setAddress(_address);
        setBalance(_balance);
        if (_provider && _address) {
            setTimeout(() => {
                check(_provider);
            }, 2000);
        }
    };
    const onConnect = async (provider) => {
        setVenomProvider(provider);
        const _address = provider ? await getAddress(provider) : undefined;
        if (_address) {
            identify(_address);
            emitWalletConnectedTrack(_address);
            const data = new FormData();
            data.append('address', _address);
            data.append('bio', "");
            data.append('twitterImage', "");
            data.append('twitterScreenName', "");
            data.append('name', "");
            data.append('title', "");
            data.append('email', "");
            data.append('twitterName', "");
            data.append('image', "");
            backendInstance.post('/user', data);
        }
        check(provider);
    };
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // provider.init()
            onInitButtonClick();
        }
    }, []);
    useEffect(() => {
        setVenomWallet({
            provider: venomProvider,
            address,
            balance,
            account: { address: address ? new Address(address) : undefined, publicKey },
            type: typeNetwork
        });
    }, [venomConnect, venomProvider, address, balance, typeNetwork]);
    useEffect(() => {
        const off = venomConnect?.on('connect', onConnect);
        return () => {
            off?.();
        };
    }, [venomConnect]);
    return (_jsx(Layout, { everWallet: everWallet, typeNetwork: typeNetwork, venomConnect: venomConnect, venomProvider: venomProvider, address: address, balance: balance, setTypeNetwork: setTypeNetwork, venomWallet: venomWallet, children: _jsx(Suspense, { fallback: _jsx(LoadingScreen, {}), children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), venomWallet?.address && (_jsx(_Fragment, { children: _jsx(Route, { path: "/create-vault", element: _jsx(CreateVault, { venomWallet: venomWallet }) }) })), _jsx(Route, { path: "/all-funds", element: _jsx(AllFunds, {}) }), _jsx(Route, { path: "/fund/:id", element: _jsx(Fund, { venomWallet: venomWallet, everWallet: everWallet, typeNetwork: typeNetwork, venomConnect: venomConnect, balance: balance }) }), _jsx(Route, { path: "/connected-wallets", element: _jsx(ConnectedWallets, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) }) }));
};
