var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { rgba } from "polished";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import styled from "styled-components";
import { CloseCross } from "./CloseCross";
export var SECONDS = 0.25;
var SBackdrop = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: all ", "s ease-in-out;\n\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  color: ", ";\n\n  cursor: default;\n\n  position: fixed;\n\n  width: 100%;\n  height: 100%;\n  z-index: 999999999999;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n\n  transition: opacity 0.1s ease-in-out;\n  will-change: opacity;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n\n  top: ", ";\n  background: ", ";\n  backdrop-filter: ", ";\n  /* ========================= */\n\n  & * {\n    box-sizing: border-box !important;\n  }\n"], ["\n  transition: all ", "s ease-in-out;\n\n  box-sizing: border-box;\n  font-family: \"Poppins\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  color: ", ";\n\n  cursor: default;\n\n  position: fixed;\n\n  width: 100%;\n  height: 100%;\n  z-index: 999999999999;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n\n  transition: opacity 0.1s ease-in-out;\n  will-change: opacity;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n\n  top: ", ";\n  background: ", ";\n  backdrop-filter: ", ";\n  /* ========================= */\n\n  & * {\n    box-sizing: border-box !important;\n  }\n"])), SECONDS / 2, function (_a) {
    var color = _a.popup.text.color;
    return color;
}, function (_a) {
    var show = _a.show;
    return (show ? 1 : 0);
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "visible" : "hidden");
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "auto" : "none");
}, function (_a) {
    var offset = _a.backdrop.offset;
    return typeof offset === "string" ? offset : "-".concat(offset || 0, "px");
}, function (_a) {
    var _b = _a.backdrop, color = _b.color, opacity = _b.opacity;
    var alpha = 0.4;
    if (typeof opacity === "number") {
        alpha = opacity;
    }
    return rgba(color, alpha);
}, function (_a) {
    var backdropFilter = _a.backdrop.backdropFilter;
    return backdropFilter;
});
var SModalContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transition: all ", "s ease-in-out;\n\n  position: relative;\n\n  width: 100vw;\n  height: 100vh;\n  padding: 15px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n  /* ========================= */\n"], ["\n  transition: all ", "s ease-in-out;\n\n  position: relative;\n\n  width: 100vw;\n  height: 100vh;\n  padding: 15px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n  /* ========================= */\n"])), SECONDS, function (_a) {
    var show = _a.show;
    return (show ? 1 : 0);
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "visible" : "hidden");
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "auto" : "none");
});
var SHitbox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n"])));
var SModalCardWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  transition: all ", "s ease-in-out;\n\n  width: 100%;\n  max-height: 100%;\n  margin: 10px;\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n\n  max-width: ", ";\n  padding: ", ";\n  background: ", ";\n  border-radius: ", ";\n  /* ========================= */\n"], ["\n  transition: all ", "s ease-in-out;\n\n  width: 100%;\n  max-height: 100%;\n  margin: 10px;\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  /* ========================= */\n  opacity: ", ";\n  visibility: ", ";\n  pointer-events: ", ";\n\n  max-width: ", ";\n  padding: ", ";\n  background: ", ";\n  border-radius: ", ";\n  /* ========================= */\n"])), SECONDS, function (_a) {
    var show = _a.show;
    return (show ? 1 : 0);
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "visible" : "hidden");
}, function (_a) {
    var visible = _a.visible;
    return (visible ? "auto" : "none");
}, function (_a) {
    var maxWidth = _a.maxWidth;
    return (maxWidth ? "".concat(maxWidth, "px") : "400px");
}, function (_a) {
    var width = _a.border.width;
    return "".concat(width, "px");
}, function (_a) {
    var color = _a.border.color;
    return color;
}, function (_a) {
    var borderRadius = _a.border.borderRadius;
    return typeof borderRadius === "string" ? borderRadius : "".concat(borderRadius, "px");
});
var SModalCard = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  /* ========================= */\n  padding: 28px\n    ", ";\n  background: ", ";\n  backdrop-filter: ", ";\n  border-radius: ", ";\n  /* ========================= */\n\n  background-position: center;\n"], ["\n  position: relative;\n\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  /* ========================= */\n  padding: 28px\n    ", ";\n  background: ", ";\n  backdrop-filter: ", ";\n  border-radius: ", ";\n  /* ========================= */\n\n  background-position: center;\n"])), function (_a) {
    var width = _a.popup.border.width;
    return "".concat(40 - width, "px");
}, function (_a) {
    var color = _a.popup.background.color;
    return color;
}, function (_a) {
    var backdropFilter = _a.popup.background.backdropFilter;
    return backdropFilter;
}, function (_a) {
    var borderRadius = _a.popup.border.borderRadius;
    return typeof borderRadius === "string" ? borderRadius : "".concat(borderRadius - 1, "px");
});
var SCardHeader = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  text-align: left;\n\n  font-weight: ", ";\n  font-size: ", ";\n  line-height: ", ";\n"], ["\n  width: 100%;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  text-align: left;\n\n  font-weight: ", ";\n  font-size: ", ";\n  line-height: ", ";\n"])), function (_a) {
    var fontWeight = _a.fontWeight;
    return fontWeight || 600;
}, function (_a) {
    var fontSize = _a.fontSize;
    return (typeof fontSize === "number" ? "".concat(fontSize, "px") : fontSize) || "24px";
}, function (_a) {
    var lineHeight = _a.lineHeight;
    return (typeof lineHeight === "number" ? "".concat(lineHeight, "px") : lineHeight) ||
        "28px";
});
var STextAlign = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  text-align: ", ";\n"], ["\n  width: 100%;\n  text-align: ", ";\n"])), function (_a) {
    var textAlign = _a.textAlign;
    return "".concat(textAlign || "center");
});
var SScrollSection = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 364px;\n\n  height: 100%;\n  max-height: ", ";\n\n  padding: 0 22px;\n  margin: 0 -22px;\n\n  overflow-x: hidden;\n  overflow-y: auto;\n  direction: ltr;\n  scrollbar-color: transparent;\n  scrollbar-width: all;\n\n  &::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: transparent;\n    border-radius: 3px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 3px;\n    background-color: ", ";\n  }\n"], ["\n  max-width: 364px;\n\n  height: 100%;\n  max-height: ", ";\n\n  padding: 0 22px;\n  margin: 0 -22px;\n\n  overflow-x: hidden;\n  overflow-y: auto;\n  direction: ltr;\n  scrollbar-color: transparent;\n  scrollbar-width: all;\n\n  &::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: transparent;\n    border-radius: 3px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    border-radius: 3px;\n    background-color: ", ";\n  }\n"])), function (_a) {
    var isDesktop = _a.isDesktop;
    return "".concat(isDesktop ? "min(calc(100vh - 210px), 360px)" : "100vh");
}, function (_a) {
    var color = _a.color;
    return color;
});
var SChildren = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 10px;\n  opacity: ", ";\n\n  animation-duration: ", "s;\n  animation-name: ", ";\n\n  @keyframes children {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"], ["\n  width: 100%;\n  margin-top: 10px;\n  opacity: ", ";\n\n  animation-duration: ", "s;\n  animation-name: ", ";\n\n  @keyframes children {\n    from {\n      opacity: 0;\n    }\n\n    to {\n      opacity: 1;\n    }\n  }\n"])), function (_a) {
    var animation = _a.animation;
    return (animation ? 1 : 0);
}, SECONDS, function (_a) {
    var animation = _a.animation;
    return (animation ? "children" : "");
});
var SBadge = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 24px;\n\n  font-size: 13px;\n  line-height: 20px;\n\n  text-align: center;\n\n  color: ", ";\n"], ["\n  width: 100%;\n  margin-top: 24px;\n\n  font-size: 13px;\n  line-height: 20px;\n\n  text-align: center;\n\n  color: ", ";\n"])), function (_a) {
    var color = _a.color;
    return "".concat(color);
});
var AbstractPopUp = function (_a) {
    var _b, _c;
    var outerShow = _a.show, hide = _a.hide, pause = _a.pause, goBack = _a.goBack, onClose = _a.onClose, themeObject = _a.themeObject, cardHeader = _a.cardHeader, children = _a.children;
    var _d = useState(false), show = _d[0], setShow = _d[1];
    useEffect(function () {
        if (outerShow) {
            setShow(outerShow);
        }
        var setShowWrapper = function () {
            setShow(outerShow);
        };
        var id = outerShow
            ? undefined
            : setTimeout(setShowWrapper, SECONDS * 1000);
        return function () {
            clearTimeout(id);
        };
    }, [outerShow]);
    useEffect(function () {
        var onCloseWrapper = function () {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        };
        var id = show ? undefined : setTimeout(onCloseWrapper, SECONDS * 1000);
        return function () {
            clearTimeout(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    useEffect(function () {
        if (hide) {
            setShow(false);
        }
    }, [hide]);
    var _e = useState(cardHeader.text), key = _e[0], setKey = _e[1];
    useEffect(function () {
        var fn = function () {
            setKey(cardHeader.text || "");
        };
        var id = setTimeout(fn, 1);
        return function () {
            clearTimeout(id);
        };
    }, [cardHeader.text]);
    return (_jsx(SBackdrop, __assign({ show: outerShow, visible: outerShow, backdrop: (_b = themeObject.common) === null || _b === void 0 ? void 0 : _b.backdrop, popup: themeObject.popup }, { children: _jsxs(SModalContainer, __assign({ show: show, visible: outerShow }, { children: [_jsx(SHitbox, { onClick: goBack ||
                        (function () {
                            if (onClose) {
                                setShow(false);
                            }
                        }) }), _jsx(SModalCardWrapper, __assign({ show: show, visible: outerShow, border: themeObject.popup.border }, { children: _jsxs(SModalCard, __assign({ popup: themeObject.popup }, { children: [_jsxs(SCardHeader, __assign({ fontSize: cardHeader.fontSize, fontWeight: (_c = themeObject.popup.title) === null || _c === void 0 ? void 0 : _c.fontWeight }, { children: [_jsx(STextAlign, __assign({ textAlign: cardHeader.textAlign }, { children: cardHeader.text })), !!onClose && (_jsx(CloseCross, { color: themeObject.popup.closeCross.color, hoverColor: themeObject.popup.closeCross.hoverColor, onClick: function () { return setShow(false); } }))] })), _jsxs(SChildren, __assign({ animation: key === cardHeader.text }, { children: [_jsx(SScrollSection, __assign({ color: themeObject.popup.scroll.color, isDesktop: isDesktop }, { children: children })), _jsxs(SBadge, __assign({ color: themeObject.popup.badgeColor }, { children: ["powered by", " ", _jsx("a", __assign({ href: "https://web3.space/venom-connect", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("b", { children: "web3.space" }) }))] }))] }))] })) }))] })) })));
};
export default AbstractPopUp;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
