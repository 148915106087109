var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as deviceDetect from "react-device-detect";
export var log = function (_a) {
    var _b;
    var _c = _a.type, type = _c === void 0 ? "log" : _c, key = _a.key, value = _a.value;
    return (_b = console[type]) === null || _b === void 0 ? void 0 : _b.call(console, "".concat(key ? key + ": " : "").concat(value));
};
export var getKey = function (name, type) { return "".concat(name, "/").concat(type); };
var disableLogs = true;
export var makeMove = function (logger, cb) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!disableLogs)
                    log({
                        key: logger.key,
                        value: logger.before,
                    });
                return [4 /*yield*/, cb()];
            case 1:
                response = _a.sent();
                if (!disableLogs)
                    log({
                        key: logger.key,
                        value: logger.after,
                    });
                return [2 /*return*/, response];
            case 2:
                error_1 = _a.sent();
                if (!disableLogs)
                    log({
                        type: "error",
                        key: logger.key,
                        value: logger.error,
                    });
                throw error_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
/**
 * ["isChrome", "isDesktop"]
 *
 * [["isChrome", "isDesktop"], ["isFirefox", "isDesktop"]]
 *
 * "isDesktop"
 */
export var checkIsCurrentBrowser = function (isCurrentBrowser) {
    var _isCurrentBrowser;
    try {
        if (isCurrentBrowser &&
            Array.isArray(isCurrentBrowser) &&
            isCurrentBrowser.reduce(function (r, br) { return r && !Array.isArray(br); }, true)) {
            // format: ["isChrome", "isDesktop"]
        }
        else if (isCurrentBrowser && Array.isArray(isCurrentBrowser)) {
            _isCurrentBrowser = isCurrentBrowser === null || isCurrentBrowser === void 0 ? void 0 : isCurrentBrowser.reduce(function (r, item, i) {
                // format: [["isChrome", "isDesktop"], ["isFirefox", "isDesktop"]]
                return (r ||
                    !!(item === null || item === void 0 ? void 0 : item.reduce(function (rInner, itemInner) {
                        var _a;
                        // @ts-ignore
                        if (deviceDetect[itemInner] === undefined) {
                            return (rInner &&
                                ((_a = deviceDetect.engineName) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase()) ===
                                    (itemInner === null || itemInner === void 0 ? void 0 : itemInner.toLocaleLowerCase()));
                        }
                        // @ts-ignore
                        return rInner && deviceDetect[itemInner];
                    }, true)));
            }, false);
        }
        else {
            // format: "isDesktop"
            // @ts-ignore
            _isCurrentBrowser = deviceDetect[isCurrentBrowser];
        }
    }
    catch (error) {
        _isCurrentBrowser = false;
    }
    return {
        isCurrentBrowser: !!_isCurrentBrowser,
    };
};
