import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import menu from './menu';
import LOGO from '../../assets/imgs/text-logo.svg';
import '../../assets/css/sidebar.css';
export const SidebarCustom = ({ collapsed, hide, venomWallet }) => {
    const matches = useMediaQuery('(max-width: 1236px)'); // 1536px
    const location = useLocation();
    const [collapseBehavior, setCollapseBehavior] = useState('hide');
    useEffect(() => {
        setCollapseBehavior(matches ? 'hide' : 'collapse');
    }, [matches]);
    useEffect(() => {
        if (matches && !collapsed) {
            document.body.style.overflowY = 'hidden';
        }
        else {
            document.body.style.overflowY = 'auto';
        }
    }, [matches, collapsed]);
    return (_jsx(_Fragment, { children: !matches && !collapsed ? (_jsx("aside", { id: "default-sidebar", className: "z-10 w-[320px] h-screen rounded-md dark:bg-gray-800 delay-75 shadow-md shadow-shadow_color", "aria-label": "logo-sidebar", children: _jsxs("div", { className: "h-full px-3 pb-2 pt-[5px] overflow-y-auto bg-white dark:bg-gray-800 logo rounded-md ", children: [_jsx(Link, { to: "https://dexify.io", className: "h-[58px] pt-4 flex items-center cursor-pointer", children: _jsx("img", { src: LOGO, alt: "Flowbite logo", className: "mr-3" }) }), _jsx("nav", { className: "md:mt-[42px] space-y-8", children: menu.map(menuItem => (_jsxs(Link, { to: menuItem.href, onClick: () => (matches ? hide() : {}), className: (location.pathname === menuItem.href
                                ? 'bg-[#8C52FF] text-white filter-svg'
                                : '') + (menuItem.isProtected && !venomWallet?.address ? 'hidden' : 'block')
                                + ' py-[12.5px] pl-[10px] pr-[42px] w-full hover:bg-hoverColor rounded-[15px] flex items-center gap-[12px] text-[14px] md:text-[18px] text-description', children: [menuItem.icon, _jsxs("div", { className: `relative ${!menuItem.isAdded ? 'pb-5' : ''}`, children: [_jsx("div", { className: "text-[14px] md:text-[18px]", children: menuItem.title }), !menuItem.isAdded ? _jsx("div", { className: "absolute top-[27px] text-xs w-max", children: "Coming soon" }) : null] })] }, menuItem.title))) })] }) })) : (!collapsed && (_jsx("aside", { id: "default-sidebar", className: "z-10 w-[300px] h-screen rounded-md dark:bg-gray-800 delay-75 shadow-md shadow-shadow_color fixed left-0 top-0", "aria-label": "logo-sidebar-mobile", children: _jsx("div", { className: "h-full px-3 pb-2 pt-[5px] overflow-y-auto bg-white dark:bg-gray-800", children: _jsx("nav", { className: "pt-[90px]", children: menu.map(menuItem => (_jsxs(Link, { to: menuItem.href, onClick: () => (matches ? hide() : {}), className: (location.pathname === menuItem.href
                            ? 'active filter-svg'
                            : '') + (menuItem.isProtected && !venomWallet?.address ? 'hidden' : 'block')
                            + ' py-[12.5px] pl-[10px] pr-[10px] w-full hover:bg-hoverColor mt-[20px] md:mt-[20px] rounded-[15px] flex items-center gap-[12px] text-[14px] md:text-[18px] text-description', children: [menuItem.icon, _jsxs("div", { className: `relative ${!menuItem.isAdded ? 'pb-5' : ''}`, children: [_jsx("div", { className: "text-[20px]", children: menuItem.title }), !menuItem.isAdded ? _jsx("div", { className: "absolute top-[27px] text-xs w-max", children: "Coming soon" }) : null] })] }, menuItem.title))) }) }) }))) }));
};
