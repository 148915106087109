import { jsx as _jsx } from "react/jsx-runtime";
import { HiHome, HiCurrencyDollar, HiUserAdd, HiOutlineLightBulb } from 'react-icons/hi';
const menu = [
    {
        title: 'Home',
        href: '/',
        icon: _jsx(HiHome, { size: 25 }),
        isProtected: false,
        isAdded: true
    },
    {
        title: 'Portfolio',
        href: '/portfolio',
        icon: _jsx(HiCurrencyDollar, { size: 25 }),
        isProtected: true,
        isAdded: false
    },
    {
        title: 'Manage',
        href: '/manage',
        icon: _jsx(HiUserAdd, { size: 25 }),
        isProtected: true,
        isAdded: false
    },
    {
        title: 'Learn',
        href: '/learn',
        icon: _jsx(HiOutlineLightBulb, { size: 25 }),
        isProtected: false,
        isAdded: false
    }
];
export default menu;
