import { formatUnits } from 'ethers';
export function addStr(addr) {
    if (!addr || addr.length < 64)
        return '';
    return addr.replace(addr.slice(5, 62), '...');
}
export function weiToEth(nanoAmount, decimals) {
    // console.log(nanoAmount)
    if (!nanoAmount)
        return '0';
    const amount = formatUnits(nanoAmount, decimals);
    console.log('amount', amount);
    let stringAmount = Number(amount).toFixed(2);
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toFixed(3);
    }
    if (Number(stringAmount) === 0) {
        stringAmount = Number(amount).toFixed(1);
    }
    return stringAmount;
}
