var _a;
import darkTheme from "./dark";
import lightTheme from "./light";
import venomTheme from "./venom";
export var themesList = (_a = {
        default: lightTheme
    },
    _a[lightTheme.name] = lightTheme,
    _a[darkTheme.name] = darkTheme,
    _a[venomTheme.name] = venomTheme,
    _a);
export var getThemeConfig = function (theme) {
    return typeof theme === "string"
        ? themesList[theme]
        : {
            name: "custom",
            theme: theme,
        };
};
