var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { filterNameArr, notSupported } from "./NotSupported";
var SWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
var SProviderContainerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  cursor: pointer;\n\n  background: transparent;\n\n  /* ========================= */\n  margin-top: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  background: ", ";\n  border-width: ", ";\n  border-color: transparent;\n  border-style: solid;\n  /* ========================= */\n\n  @media (hover: hover) {\n    :hover {\n      border-color: ", ";\n\n      /* ========================= */\n      padding: ", ";\n      background: ", ";\n      /* ========================= */\n    }\n    :active {\n      border-width: 0;\n\n      /* ========================= */\n      padding: ", ";\n      background: ", ";\n      /* ========================= */\n\n      & > div > div {\n        user-select: none;\n        filter: contrast(0%) brightness(0%) opacity(0.3); // ?\n      }\n    }\n  }\n"], ["\n  width: 100%;\n  overflow: hidden;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  cursor: pointer;\n\n  background: transparent;\n\n  /* ========================= */\n  margin-top: ", ";\n  border-radius: ", ";\n  padding: ", ";\n  background: ", ";\n  border-width: ", ";\n  border-color: transparent;\n  border-style: solid;\n  /* ========================= */\n\n  @media (hover: hover) {\n    :hover {\n      border-color: ", ";\n\n      /* ========================= */\n      padding: ", ";\n      background: ", ";\n      /* ========================= */\n    }\n    :active {\n      border-width: 0;\n\n      /* ========================= */\n      padding: ", ";\n      background: ", ";\n      /* ========================= */\n\n      & > div > div {\n        user-select: none;\n        filter: contrast(0%) brightness(0%) opacity(0.3); // ?\n      }\n    }\n  }\n"])), function (_a) {
    var isShowCheckWarnings = _a.isShowCheckWarnings, isFirst = _a.isFirst;
    return isShowCheckWarnings || isFirst ? 0 : "10px";
}, function (_a) {
    var borderRadius = _a.popup.border.borderRadius;
    return "".concat(borderRadius / 2 + 1, "px");
}, function (_a) {
    var width = _a.item.border.width;
    return "".concat(width, "px");
}, function (_a) {
    var color = _a.item.border.color;
    return color;
}, function (_a) {
    var borderWidth = _a.item.border.borderWidth;
    return "".concat(borderWidth, "px");
}, function (_a) {
    var borderHoverColor = _a.item.border.borderHoverColor;
    return borderHoverColor;
}, function (_a) {
    var width = _a.item.border.width;
    return "".concat(width, "px");
}, function (_a) {
    var hoverColor = _a.item.border.hoverColor;
    return hoverColor;
}, function (_a) {
    var _b = _a.item.border, width = _b.width, borderWidth = _b.borderWidth;
    return "".concat(width + borderWidth, "px");
}, function (_a) {
    var pressedColor = _a.item.border.pressedColor;
    return pressedColor;
});
var SProviderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n\n  overflow: hidden;\n\n  display: flex;\n  justify-content: left;\n  align-items: center;\n\n  transition: background-color 0.2s ease-in-out;\n\n  /* ========================= */\n  border-radius: ", ";\n  background: ", ";\n  /* ========================= */\n\n  background-position: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n\n  overflow: hidden;\n\n  display: flex;\n  justify-content: left;\n  align-items: center;\n\n  transition: background-color 0.2s ease-in-out;\n\n  /* ========================= */\n  border-radius: ", ";\n  background: ", ";\n  /* ========================= */\n\n  background-position: center;\n"])), function (_a) {
    var borderRadius = _a.borderRadius;
    return "".concat(borderRadius / 2, "px");
}, function (_a) {
    var color = _a.item.background.color;
    return color;
});
var SIconSection = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* ========================= */\n  width: ", ";\n  min-width: ", ";\n  padding-left: ", ";\n  /* ========================= */\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* ========================= */\n  width: ", ";\n  min-width: ", ";\n  padding-left: ", ";\n  /* ========================= */\n"])), function (_a) {
    var width = _a.item.border.width;
    return "".concat(56 - width, "px");
}, function (_a) {
    var width = _a.item.border.width;
    return "".concat(56 - width, "px");
}, function (_a) {
    var width = _a.item.border.width;
    return "".concat(8 - width, "px");
});
var SMainSection = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  box-sizing: border-box;\n\n  /* flex-grow: 1; */\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 5px;\n\n  min-height: 60px;\n\n  text-align: left;\n\n  /* ========================= */\n  padding: ", ";\n  padding-left: 0;\n  /* ========================= */\n"], ["\n  box-sizing: border-box;\n\n  /* flex-grow: 1; */\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  gap: 5px;\n\n  min-height: 60px;\n\n  text-align: left;\n\n  /* ========================= */\n  padding: ", ";\n  padding-left: 0;\n  /* ========================= */\n"])), function (_a) {
    var width = _a.item.border.width;
    return "".concat(14 - width, "px");
});
var STitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 18px;\n  line-height: 20px;\n"], ["\n  font-size: 18px;\n  line-height: 20px;\n"])));
var SSubTitleText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 10px;\n  line-height: 10px;\n\n  letter-spacing: ", ";\n\n  text-align: start;\n"], ["\n  font-size: 10px;\n  line-height: 10px;\n\n  letter-spacing: ", ";\n\n  text-align: start;\n"])), function (_a) {
    var letterSpacing = _a.letterSpacing;
    return typeof letterSpacing === "number" ? "".concat(letterSpacing, "px") : letterSpacing;
});
var SSubTextList = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 16px;\n"])));
var SSubTextItem = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n  font-size: 10px;\n  line-height: 10px;\n\n  > span {\n    margin-top: 0.3em;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 4px;\n  font-size: 10px;\n  line-height: 10px;\n\n  > span {\n    margin-top: 0.3em;\n  }\n"])));
var SImg = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  max-height: ", ";\n\n  svg,\n  svg path {\n    fill: ", ";\n  }\n\n  svg {\n    height: ", ";\n  }\n"], ["\n  max-height: ", ";\n\n  svg,\n  svg path {\n    fill: ", ";\n  }\n\n  svg {\n    height: ", ";\n  }\n"])), function (_a) {
    var maxHeight = _a.maxHeight;
    return typeof maxHeight === "number" ? "".concat(maxHeight, "px") : maxHeight;
}, function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var maxHeight = _a.maxHeight;
    return typeof maxHeight === "number" ? "".concat(maxHeight, "px") : maxHeight;
});
export var ProviderCard = function (_a) {
    var _b, _c;
    var nameRaw = _a.name, logo = _a.logo, logoWhite = _a.logoWhite, description = _a.description, isProviderExist = _a.isProviderExist, isCurrentBrowser = _a.isCurrentBrowser, themeObject = _a.themeObject, themeName = _a.themeName, onClick = _a.onClick, connectorType = _a.connectorType, browserNameRaw = _a.browser, options = _a.options, isFirst = _a.isFirst, isBadBrowser = _a.isBadBrowser, allBrowsersNames = _a.allBrowsersNames, browsersNames = _a.browsersNames;
    var browserName = (_b = browserNameRaw === null || browserNameRaw === void 0 ? void 0 : browserNameRaw.toLocaleLowerCase()) === null || _b === void 0 ? void 0 : _b.trim();
    var name = nameRaw.replace("[[browser]]", isCurrentBrowser && browserNameRaw ? browserNameRaw : "Chrome");
    var isShowBadBrowserWarning = !!isFirst && !!isBadBrowser;
    var NotSupportedBadge = (_jsxs(_Fragment, { children: [!isCurrentBrowser && (_jsx(notSupported.browser.Badge, { themeObject: themeObject, browserName: 
                // (allBrowsersNames &&
                //   filterNameArr(allBrowsersNames)?.join(" or ")) ||
                "Google Chrome" })), isCurrentBrowser && (_jsx(notSupported.provider.Badge, { themeObject: themeObject, providerName: name, isVisualHide: true }))] }));
    // это плашка под экстеншенами в случае их отсутствия
    var NotSupportedText = (_jsxs(_Fragment, { children: [!isCurrentBrowser && (_jsx(SSubTitleText, { children: _jsx(notSupported.browser.Text, { browserName: (browsersNames && ((_c = filterNameArr(browsersNames)) === null || _c === void 0 ? void 0 : _c[0])) ||
                        // ?.join(" or ")
                        "" }) })), isCurrentBrowser && !isProviderExist && (_jsx(SSubTitleText, { children: _jsx(notSupported.provider.Text, { providerName: name }) }))] }));
    var getOptionsSubText = function () {
        switch (connectorType) {
            case "mobile":
                var list = options === null || options === void 0 ? void 0 : options.devises;
                var elements = Array.isArray(list)
                    ? list.map(function (_a) {
                        var img = _a.img, text = _a.text, type = _a.type;
                        return (type !== "apk" && (_jsxs(SSubTextItem, { children: [!!logo && (_jsx(_Fragment, { children: typeof img === "string" ? (_jsx("img", { src: img, alt: type || "", height: 12, style: { maxHeight: "12px" } })) : (_jsx(SImg, __assign({ color: themeObject.item.icon.subTitle.color, maxHeight: 12 }, { children: img }))) })), _jsx("span", { children: text })] })));
                    })
                    : null;
                return (elements === null || elements === void 0 ? void 0 : elements.length) ? (_jsx(SSubTextList, { children: elements })) : null;
            case "ios":
            case "android":
                return typeof (options === null || options === void 0 ? void 0 : options.text) === "string" ? options.text : null;
            default:
                break;
        }
    };
    var optionsSubText = getOptionsSubText();
    var getLinkFromArr = function (arr) {
        if (!Array.isArray(arr))
            return undefined;
        var current = arr === null || arr === void 0 ? void 0 : arr.find(function (extensionObj) { return extensionObj.browser === browserName; });
        var fallback = arr === null || arr === void 0 ? void 0 : arr.find(function (extensionObj) { return extensionObj.browser === "chrome"; });
        return current || fallback;
    };
    var getCardLink = function () {
        var _a, _b;
        // чужой браузер, вернём ссылку на установку
        if (connectorType === "extension" && !isCurrentBrowser) {
            return (_a = getLinkFromArr(options === null || options === void 0 ? void 0 : options.installExtensionLink)) === null || _a === void 0 ? void 0 : _a.link;
        }
        else if (connectorType === "extension" &&
            isCurrentBrowser &&
            !isProviderExist) {
            return (_b = getLinkFromArr(options === null || options === void 0 ? void 0 : options.installExtensionLink)) === null || _b === void 0 ? void 0 : _b.link;
        }
        else if (connectorType === "ios" || connectorType === "android") {
            return options === null || options === void 0 ? void 0 : options.deepLink;
        }
    };
    var cardLink = getCardLink();
    var getLogo = function () {
        if (typeof logo === "string") {
            return themeName === "venom" ? logoWhite : logo;
        }
        if (typeof logo === "object") {
            var __logo = logo;
            switch (browserName) {
                case "firefox":
                    return __logo.firefox || __logo.chrome;
                case "chrome":
                default:
                    return __logo.chrome;
            }
        }
        return "";
    };
    // список способов подключения
    return (_jsxs(SWrapper, __assign({ onClick: onClick }, { children: [isShowBadBrowserWarning && NotSupportedBadge, _jsx("a", __assign({}, (cardLink
                ? {
                    href: cardLink,
                }
                : {}), { target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(SProviderContainerWrapper, __assign({ item: themeObject.item, popup: themeObject.popup, isShowCheckWarnings: isShowBadBrowserWarning, isFirst: !!isFirst }, { children: _jsxs(SProviderContainer, __assign({ item: themeObject.item, borderRadius: themeObject.popup.border.borderRadius }, { children: [_jsx(SIconSection, __assign({ item: themeObject.item }, { children: !!logo && (_jsx(_Fragment, { children: _jsx("img", { src: getLogo(), alt: name, style: { maxHeight: "24px" } }) })) })), _jsxs(SMainSection, __assign({ item: themeObject.item }, { children: [_jsx(STitle, { children: name }), !!description && _jsx(SSubTitleText, { children: description }), !!optionsSubText && (_jsx(SSubTitleText, __assign({ letterSpacing: 1.02 }, { children: optionsSubText }))), NotSupportedText] }))] })) })) }))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
